import { LookupMap } from '../../../global/LookupMap';
import { ConcernLevel } from '../../../global/constants';
import { centerFloatProps } from '../../../global/helpers';
import { getTypography } from '../../../theme/_';

export interface IGaugeView {
  concernLevel: number;
  domainDefId: number;
  isScored: boolean;
  score: number;
}

export const GaugeCopyMap = new LookupMap<string>(
  {
    [ConcernLevel.NO_SCORE]: 'Incomplete',
    [ConcernLevel.NO_CONCERN]: 'Low Risk',
    [ConcernLevel.MILD]: 'Mild Risk',
    [ConcernLevel.MODERATE]: 'Medium Risk',
    [ConcernLevel.SIGNIFICANT]: 'High Risk',
  },
  'Incomplete'
);
export const wrapProps = {
  position: 'relative' as 'relative',
  display: 'table'
};
export const contentAreaProps = {
  ...centerFloatProps,
  textAlign: 'center' as 'center',
};
export const copyProps = {
  ...getTypography('caption'),
};

export const getIconSize = (chartWidth: number) => {
  const iconRatio = 70 / 150; // iconWidth / chartWidth

  return chartWidth * iconRatio;
};
