import ButtonSymptoms from '../../components/ButtonSymptoms';
import Gauge from '../../components/Gauge';
import Page from '../Page';
import PageBody from '../../components/PageBody';
import PageSection from '../../components/PageSection';
import ResultsSubFooter from '../../components/ResultsSubFooter';
import { Box, Divider } from '@chakra-ui/react';
import { Copy, H4, H6 } from '../../components/Prose';
import { GaugeViewName, IGauge } from '../../components/Gauge/Gauge';
import { IPageView } from '../Page/PageView';
import { ISymptomDatum } from '../../components/ButtonSymptom/ButtonSymptom';
import { headerSectionProps, pageBodyProps, pageHeaderProps } from './props';
import VideoPlayer from '../../components/VideoPlayer';
import ButtonFreeCourse from '../../components/ButtonFreeCourse';
import { welcomeVideoUrl } from '../../global/constants';
import ButtonRetakeSurvey from '../../components/ButtonRetakeSurvey';
import CardMobileApp from '../../components/CardMobileApp';

const contentGutter = '20px';
const gaugeWrapProps = {
  display: 'flex',
  justifyContent: 'center',
};
const dividerProps = {
  my: '5px',
};

const minimumViableScore = 2;
export interface IPageResultView extends IPageView {
  assessDate: string;
  concernLevel: number;
  domainDefId: number;
  domainName: string;
  domainVideoUrl: string;
  numPersonsCompletingDomain: number;
  pctPersonsLikeMeAsText: string;
  score: number;
  symptomsData: ISymptomDatum[];
  downloadApp: boolean;
}
const PageResultView: React.FC<IPageResultView> = ({
  assessDate,
  concernLevel,
  domainDefId,
  domainName,
  // domainVideoUrl,
  loadedConditions,
  numPersonsCompletingDomain,
  pctPersonsLikeMeAsText,
  score,
  symptomsData,
  downloadApp,
}) => {
  const pageProps = {
    loadedConditions,
    pageTitle: 'Results',
    bgColor: 'white',
  };

  const contentSectionProps = {
    textAlign: 'center' as 'center',
  };
  const h6Props = {
    color: 'green.800',
    mb: '20px',
  };
  const buttonSymptomsProps = {
    data: symptomsData,
    m: 'auto',
    maxW: '500px',
  };
  const gaugeProps: IGauge = {
    view: GaugeViewName.LARGE,
    score,
    concernLevel,
    domainDefId,
  };

  const videoSectionProps = {
    textAlign: 'center' as 'center',
  };
  const commonVideoItemProps = {
    mb: contentGutter,
  };
  const videoHeaderProps = {
    ...commonVideoItemProps,
    color: 'green.800',
    maxW: '440px',
    mx: 'auto',
  };
  const videoPlayerProps = {
    ...commonVideoItemProps,
    url: welcomeVideoUrl,
  };

  return (
    <Page {...pageProps}>
      <PageBody {...pageBodyProps}>
        <PageSection {...headerSectionProps}>
          <H4 {...pageHeaderProps}>
            Results For
            <br />
            {domainName}
          </H4>
          <Copy>Assessed on {assessDate}</Copy>
        </PageSection>
        <Divider {...dividerProps} />
        <PageSection {...contentSectionProps}>
          {score > minimumViableScore && (
            <H6 {...h6Props}>
              Among {numPersonsCompletingDomain} Cadey parents,{' '}
              {pctPersonsLikeMeAsText} have similar concerns.
            </H6>
          )}
          <Copy>
            A safe range for this assessment is typically{' '}
            <strong>{minimumViableScore} or less</strong>. Any result above this
            level indicates that your child may be at risk.
          </Copy>
        </PageSection>
        <PageSection>
          <Box {...gaugeWrapProps}>
            <Gauge {...gaugeProps} />
          </Box>
        </PageSection>
        <PageSection {...{ textAlign: 'center' }}>
          <ButtonRetakeSurvey {...{ domainDefId }} />
        </PageSection>
        <Divider {...dividerProps} />
        <PageSection>
          <ButtonSymptoms {...buttonSymptomsProps} />
        </PageSection>
        {downloadApp && (
          <PageSection {...{ textAlign: 'center' }}>
            <CardMobileApp />
          </PageSection>
        )}
      </PageBody>
      <ResultsSubFooter />
    </Page>
  );
};

export default PageResultView;
